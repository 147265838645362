import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import { scrollToContent as indexScroll } from '../../templates/IndexTemplate';
// import { scrollToContent as indexTravelScroll } from '../../templates/TravelTemplate';
// import { scrollToContent as indexFoodScroll } from '../../templates/FoodTemplate';
// import { scrollToContent as indexLatestPostScroll } from '../../templates/LatestPostTemplate';
import { scrollToContent } from '../Common/scroll-to';
// import { FiShare } from '@react-icons/all-files/fi/FiShare';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';

const Item = (props) => {
  const { theme, item: { label, to, icon: Icon, shareData, component } = {}, path } = props;
  // console.debug(`Item - path:"${path}"`);

  const scrollTo =
    path && path === '/'
      ? scrollToContent
      : path && path.includes('/travel')
      ? scrollToContent
      : path && path.includes('/food')
      ? scrollToContent
      : path && path.includes('/new')
      ? scrollToContent
      : null;
  const onShareCurrentPage = async (e) => {
    // console.debug('onShareCurrentPage shareData:', shareData);
    e.preventDefault();
    if (navigator && navigator.share) {
      // console.debug('navigator.share:', navigator.share);
      // console.debug('navigator.canShare(shareData):', navigator.canShare(shareData));
      // console.debug('canShare');
      try {
        await navigator.share(shareData);
        // console.debug('share');
        // .then(() => console.log('Successful share'))
        // .catch(error => console.log('Error sharing', error));
      } catch (error) {
        console.warn('Error sharing', error);
      }
    }
  };
  // console.debug('to', to);
  // console.debug('path', path);
  return (
    <React.Fragment>
      {component ? (
        <MenuItemStyled
          theme={theme}
          className={'hiddenItem' in props ? 'hiddenItem' : `item ${cursorPointerStyle}`}
          key={label}
        >
          {component}
        </MenuItemStyled>
      ) : (to === path || (to && path !== '/' && to.includes(path))) && scrollTo ? (
        <MenuItemStyled theme={theme} className={'hiddenItem' in props ? 'hiddenItem' : 'item'} key={label}>
          <a
            onClick={scrollTo}
            className={'hiddenItem' in props ? 'inHiddenItem' : 'scrollToTop'}
            data-slug={to}
          >
            {Icon && <Icon />} {label}
          </a>
        </MenuItemStyled>
      ) : shareData ? (
        <MenuItemStyled
          theme={theme}
          className={'hiddenItem' in props ? 'hiddenItem' : `item ${cursorShareStyle}`}
          key={label}
          onClick={onShareCurrentPage}
        >
          <a>
            {Icon && <Icon />} {label}
          </a>
        </MenuItemStyled>
      ) : (
        <MenuItemStyled theme={theme} className={'hiddenItem' in props ? 'hiddenItem' : 'item'} key={label}>
          <Link to={to} className={'hiddenItem' in props ? 'inHiddenItem' : ''} data-slug={to}>
            {Icon && <Icon />} {label}
          </Link>
        </MenuItemStyled>
      )}
    </React.Fragment>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired,
  path: PropTypes.string,
};

export default Item;

const MenuItemStyled = styled.li`
  &,
  .showItem {
    background: transparent;
    transition: all ${(props) => props.theme.time.duration.default};
    display: flex;
    align-items: center;

    a {
      padding: ${(props) => props.theme.space.inset.s};
      display: flex;
      align-items: center;
      color: var(--menuText);
    }

    a.scrollToTop {
      cursor: alias;
    }

    svg {
      opacity: 0.5;
      font-size: 1.3rem;
      margin: 0px ${(props) => props.theme.space.inset.xs};
    }
  }
  .itemList &.hideItem {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    a {
      color: var(--textLink);
      padding: ${(props) => props.theme.space.inset.s};
      transition: all ${(props) => props.theme.time.duration.default};
      border-radius: ${(props) => props.theme.size.radius.small};
    }

    .homepage:not(.fixed) & a {
      color: ${(props) =>
        props.path &&
        (props.path === '/' || props.path.includes('/travel-phu-quoc') || props.path.includes('/london-eye'))
          ? props.theme.text.color.primary
          : props.theme.color.neutral.white};
    }
    .homepage:not(.fixed) & svg {
      --svg-dark-mode-icon-stroke: ${(props) =>
        props.path &&
        (props.path === '/' || props.path.includes('/travel-phu-quoc') || props.path.includes('/london-eye'))
          ? '#222'
          : props.theme.color.neutral.white};
    }

    a:hover {
      color: ${(props) => props.theme.color.brand.roof};
      background: var(--a-bg-hover);
    }

    svg {
      transition: all ${(props) => props.theme.time.duration.default};
    }

    &:hover svg {
      fill: #ad7d65;
      opacity: 1;

      .hero & svg {
        fill: #ad7d65;
      }
    }

    .showItem {
      display: none;
    }

    &.hiddenItem {
      text-align: left;
      padding: ${(props) => props.theme.space.xs};

      a.inHiddenItem {
        color: ${(props) => props.theme.text.color.primary};
        &:hover {
          color: ${(props) => props.theme.color.brand.primary};
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &,
    .showItem {
      a {
        color: var(--menuText);
      }

      svg {
        color: var(--menuIcon);
        opacity: 0.7;
      }

      .share {
        cursor: alias;
      }
    }
  }
`;

const cursorPointerStyle = css`
  cursor: pointer;
`;
const cursorShareStyle = css`
  cursor: alias;
`;
