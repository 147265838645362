import React, { useContext } from 'react';
import { IoMdSunny } from '@react-icons/all-files/io/IoMdSunny';
import { RiMoonClearLine } from '@react-icons/all-files/ri/RiMoonClearLine';
import useDarkMode from '@fisch0920/use-dark-mode';

const DarkModeToggle = () => {
  // const isCurrentDarkMode =
  //   typeof window !== 'undefined' &&
  //   window.matchMedia &&
  //   window.matchMedia('(prefers-color-scheme: dark)').matches
  //     ? true
  //     : false;
  const isDarkMode = useDarkMode();

  return (
    <>
      <a onClick={isDarkMode.toggle}>{isDarkMode.value ? <IoMdSunny /> : <RiMoonClearLine />}</a>
    </>
  );
};

export default DarkModeToggle;
