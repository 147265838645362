// Note: Self-host font files (obtained by previous use of these package) under /fonts folder (public path - src path is /static/fonts/)
// and use our own <link> tag in html.js to preload fonts directly to reduce Cumulative Layout Shift (CLS)
// and apply them through font.css (set font-display: optional https://web.dev/preload-optional-fonts/) with <link> tag in html.js
// and import it here for gatsby / react reference
// CSS font-family: 'Open Sans', 'Noto Sans TC' <-- 'Open Sans' is placed before 'Noto Sans TC' to fix the big space after ' issue
// import 'typeface-noto-sans-tc';
// import 'typeface-open-sans';
// import 'typeface-niconne';
// import FontFaceObserver from 'fontfaceobserver';
import '../../static/fonts/font.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import {
  cookieConsentContainer,
  cookieConsentContent,
  cookieConsentButton,
  cookieConsentDeclineButton,
} from './layout.module.css';

import { ParallaxProvider } from 'react-scroll-parallax';
// import { getScreenWidth } from '../utils/helpers';
import { Link } from 'gatsby';

import Footer from '../components/Footer/';
import Header from '../components/Header';

import CookieConsent from 'react-cookie-consent';

import themeObjectFromYaml from '../theme/theme.yaml';
import debounce from 'just-debounce-it';

export const ThemeContext = React.createContext(null);
// export const ScreenWidthContext = React.createContext(0);
// export const FontLoadedContext = React.createContext(false);

//This is used by gatsby-plugin-layout plugin which enables adding components which live above the page components and persist across page changes.
const Layout = (props) => {
  // const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  // const [headerMinimized, setHeaderMinimized] = useState(false);
  const [themeFromYaml, setThemeFromYaml] = useState(themeObjectFromYaml);

  // useEffect(() => {
  //   console.log('useEffect: Layout mount');
  // Avoid performing handleWindowSizeChange at top level that sets the state passed through by context
  // Because such change will trigger the re-rendering of the children DOM tree

  //   return () => {
  //     console.log('useEffect: Layout unmount');
  //   };
  // }, []);

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      footnote: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/footnote/" } }) {
        nodes {
          html
          frontmatter {
            title
          }
        }
      }
      footerImageBg4K: imageSharp(fluid: { originalName: { regex: "/footer-background/" } }) {
        # for 2560 4K use higher resolution image
        resize(width: 3840, quality: 70, cropFocus: CENTER) {
          src
        }
      }
      footerImageBgQHD: imageSharp(fluid: { originalName: { regex: "/footer-background/" } }) {
        # for 1800 QHD use higher resolution image
        resize(width: 2560, quality: 70, cropFocus: CENTER) {
          src
        }
      }
      footerImageBgDesktop: imageSharp(fluid: { originalName: { regex: "/footer-background/" } }) {
        resize(width: 1440, quality: 70, cropFocus: CENTER) {
          src
        }
      }
      footerImageBgTablet: imageSharp(fluid: { originalName: { regex: "/footer-background/" } }) {
        resize(width: 800, quality: 70, cropFocus: CENTER) {
          src
        }
      }
      footerImageBgMobile: imageSharp(fluid: { originalName: { regex: "/footer-background/" } }) {
        resize(width: 450, height: 850, quality: 70, cropFocus: CENTER) {
          src
        }
      }
    }
  `);
  const { children } = props;
  const {
    footnote: { nodes: footnotes = [] },
    footerImageBg4K: {
      resize: { src: footerBgFourK },
    },
    footerImageBgQHD: {
      resize: { src: footerBgQHD },
    },
    footerImageBgDesktop: {
      resize: { src: footerBgDesktop },
    },
    footerImageBgTablet: {
      resize: { src: footerBgTablet },
    },
    footerImageBgMobile: {
      resize: { src: footerBgMobile },
    },
  } = data;

  // console.log('data:', data);

  const footerBackgrounds = {
    footerBgFourK,
    footerBgQHD,
    footerBgDesktop,
    footerBgTablet,
    footerBgMobile,
  };

  const footnote = footnotes.filter((item) => item.frontmatter.title === 'footnote');
  const footnoteHTML = footnote && footnote.length === 1 ? footnote[0].html : null;

  return (
    <ThemeContext.Provider value={themeFromYaml}>
      {/* <FontLoadedContext.Provider value={true}> */}
      {/* <ScreenWidthContext.Provider value={screenWidth}> */}
      {/* <ParallaxProvider> */}
      <React.Fragment>
        <Header path={props.location.pathname} theme={themeFromYaml} />
        <MainStyled>{children}</MainStyled>
        <Footer html={footnoteHTML} theme={themeFromYaml} backgrounds={footerBackgrounds} />
        <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText="我知道了"
          cookieName="tflcc"
          containerClasses={cookieConsentContainer}
          contentClasses={cookieConsentContent}
          buttonClasses={cookieConsentButton}
          declineButtonClasses={cookieConsentDeclineButton}
          declineButtonText="了解更多"
          extraCookieOptions={{ SameSite: 'Strict', secure: true }}
          flipButtons
          onDecline={() => {
            navigate('/cookies-policy/');
          }}
        >
          本網站使用 cookies
          以便為您提供更優質的使用體驗。若您點選"我知道了"或繼續閱覽本網站內容，即表示您同意我們的 cookies
          條款。欲瞭解更多 cookies 以及相關政策更新資訊，請閱讀我們的{' '}
          <Link to="/privacy-policy/" style={{ color: '#ffc53d' }}>
            隱私政策
          </Link>{' '}
          與
          <Link to="/cookies-policy/" style={{ color: '#ffc53d' }}>
            {' '}
            cookies 條款
          </Link>
        </CookieConsent>
      </React.Fragment>
      {/* </ParallaxProvider> */}
      {/* </ScreenWidthContext.Provider> */}
      {/* </FontLoadedContext.Provider> */}
    </ThemeContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  backgrounds: PropTypes.object,
};

export default Layout;

const MainStyled = styled.main`
  min-height: 80vh;
`;

export const globals = css`
  :global() {
    html {
      box-sizing: border-box;

      @media  (min-width: 980px) {
        scroll-padding-top: 60px;
      }
    }
    *,
    *:after,
    *:before {
      box-sizing: inherit;
      margin: 0;
      padding: 0;
    }
    body {
      font-family: 'Open Sans', 'Noto Sans TC', '微軟正黑體', '繁黑體',
        'Microsoft JhengHei', 'Microsoft YaHei', '新細明體', PMingLiU, sans-serif;
      -webkit-font-smoothing: antialiased;
      background-color: var(--bg);
      transition: color 0.3s ease-out,
        background 0.3s ease-out;
    }
    body.light-mode {
      --bg: hsl(0, 0%, 95%);
      --menuBg: #edfafd;
      --menuText: #00778f;
      --menuIcon: #02033b;
      --menuDesktopFixedExpandIcon: black;
      --menuDesktopExpandBg: white;
      --menuMobileExpandBg: #edfafd;
      --textNormal: #222;
      --textTitle: #222;
      --textLink: #666;
      --textLink2: #709425;
      --textLink2Hover: midnightblue;
      --textCode: hsla(196, 13%, 18%, 1);
      --textCodeBlock: hsla(196, 13%, 18%, 1);
      --backgroundCode: linear-gradient(90deg, rgba(242,242,242,1) 0%, rgba(255,245,179,1) 5%, rgba(255,245,179,1) 50%, rgba(255,245,179,1) 95%, rgba(242,242,242,1) 100%);
      --backgroundCodeBlock: linear-gradient(90deg, rgba(242,242,242,1) 0%, rgba(255,245,179,1) 5%, rgba(255,245,179,1) 50%, rgba(255,245,179,1) 95%, rgba(242,242,242,1) 100%);
      --blockquoteText: hsla(208, 63%, 34%, 1);
      --blockquoteBg: aliceblue;
      --codeMarkdownText: hsl(211deg 87% 25%);
      --codeMarkdownBg: hsla(50, 83%, 74%, 0.4);
      --featureExcerptText: hsla(208, 63%, 34%, 1);
      --featureExcerptBg: aliceblue;
      --hr: hsla(0, 0%, 0%, 0.2);
      --inlineCode-bg: rgba(255, 229, 100, 0.2);
      --inlineCode-text: #1a1a1a;
      --blog-item-hover-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
      --blog-item-transition: all 0.3s;
      --a-bg-hover: color(white alpha(-60%));
      --textTitleLink: hsla(79, 61%, 23%, 1);
      --svg-dark-mode-icon-stroke: #222;
      --border-line-color: #ecebea;
      --underline-color: hsla(149, 58%, 65%, 1);
      --underline-color-link: hsla(199, 77%, 86%, 1);
    }
    body.dark-mode {
      --bg: hsl(0, 1%, 15%);
      --menuBg: hsl(0 1% 20% / 1);
      --menuText: #ffc53d;
      --menuIcon: #ffe4c4;
      --menuDesktopFixedExpandIcon: #ffc53d;
      --menuDesktopExpandBg: hsl(0, 0%, 95%);
      --menuMobileExpandBg: hsl(0 1% 20% / 1);
      --textNormal: rgba(255, 255, 255, 0.88);
      --textTitle: white;
      --textLink: #ffc53d;
      --textLink2: #ffc53d;
      --textLink2Hover: palegreen;
      --textCode: hsla(60, 41%, 88%, 1);
      --textCodeBlock: hsla(60, 41%, 88%, 1);
      --backgroundCode: linear-gradient(90deg, rgba(39,38,38,1) 0%, rgba(78,78,75,1) 5%, rgba(78,78,75,1) 50%, rgba(78,78,75,1) 95%, rgba(39,38,38,1) 100%);
      --backgroundCodeBlock: linear-gradient(90deg, rgba(39,38,38,1) 0%, rgba(78,78,75,1) 5%, rgba(78,78,75,1) 50%, rgba(78,78,75,1) 95%, rgba(39,38,38,1) 100%);
      --blockquoteText: hsl(0deg 89% 86%);
      --blockquoteBg: hsl(0deg 2% 12%);
      --codeMarkdownText: hsl(58deg 73% 90%);
      --codeMarkdownBg: hsl(0deg 1% 20%);
      --featureExcerptText: hsla(17, 100%, 77%, 1);
      --featureExcerptBg: hsla(0, 0%, 30%, 1);
      --hr: hsla(0, 0%, 100%, 0.2);
      --inlineCode-bg: rgba(67, 97, 149, 0.5);
      --inlineCode-text: #e6e6e6;
      --blog-item-hover-box-shadow: none;
      --blog-item-transition: none;
      --a-bg-hover: #ffffff33;
      --textTitleLink: bisque;
      --svg-dark-mode-icon-stroke: white;
      --border-line-color: none;
      --underline-color: hsla(149, 58%, 33%, 1);
      --underline-color-link: hsla(199, 77%, 35%, 1);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--textTitle);
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: -0.03em;
      margin: 0;
    }
    h1 {
      letter-spacing: -0.04em;
    }
    figcaption {
      color: var(--textNormal);
      font-style: italic;
      font-size: 0.8em;
    }
    .autolink {
      color: var(--textLink);
    }
    p {
      margin: 0;
      color: var(--textNormal);
    }
    li {
      color: var(--textNormal);
    }
    strong {
      font-weight: 600;
    }
    a {
      text-decoration: none;
      color: var(--textLink);
    }
    a.externalLink {
      color: var(--textLink2);
    }
`;
