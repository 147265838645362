exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contentful-post-slug-js": () => import("./../../../src/pages/{contentfulPost.slug}.js" /* webpackChunkName: "component---src-pages-contentful-post-slug-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-food-template-js": () => import("./../../../src/templates/FoodTemplate.js" /* webpackChunkName: "component---src-templates-food-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/IndexTemplate.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-latest-post-template-js": () => import("./../../../src/templates/LatestPostTemplate.js" /* webpackChunkName: "component---src-templates-latest-post-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/TagsTemplate.js" /* webpackChunkName: "component---src-templates-tags-template-js" */),
  "component---src-templates-travel-feature-template-js": () => import("./../../../src/templates/TravelFeatureTemplate.js" /* webpackChunkName: "component---src-templates-travel-feature-template-js" */),
  "component---src-templates-travel-template-js": () => import("./../../../src/templates/TravelTemplate.js" /* webpackChunkName: "component---src-templates-travel-template-js" */)
}

