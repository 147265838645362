import React from 'react';
import { FaUserFriends } from '@react-icons/all-files/fa/FaUserFriends';
import { IoIosAirplane } from '@react-icons/all-files/io/IoIosAirplane';
import { FaRegPaperPlane } from '@react-icons/all-files/fa/FaRegPaperPlane';
import { FaTag } from '@react-icons/all-files/fa/FaTag';
import { TiVideo } from '@react-icons/all-files/ti/TiVideo';
import { MdRestaurant } from '@react-icons/all-files/md/MdRestaurant';
import { GiIsland } from '@react-icons/all-files/gi/GiIsland';
import { GiModernCity } from '@react-icons/all-files/gi/GiModernCity';

export const getCategoryIcon = (categoryMenuTitle, withJSX = false, className = 'arrow', size = '') => {
  let icon;
  switch (categoryMenuTitle) {
    case '關於':
      icon = FaUserFriends;
      break;
    case '旅行日誌':
      if (withJSX) {
        icon = <IoIosAirplane className={className} />;
        if (size !== '') icon = <IoIosAirplane className={className} size={size} />;
      } else icon = IoIosAirplane;
      break;
    case '美味廚房':
      if (withJSX) {
        icon = <MdRestaurant className={className} />;
        if (size !== '') icon = <MdRestaurant className={className} size={size} />;
      } else icon = MdRestaurant;
      break;
    case '生活手扎':
      if (withJSX) {
        icon = <FaRegPaperPlane className={className} />;
        if (size !== '') icon = <FaRegPaperPlane className={className} size={size} />;
      } else icon = FaRegPaperPlane;
      break;
    case '影片分享':
      if (withJSX) {
        icon = <TiVideo className={className} />;
        if (size !== '') icon = <TiVideo className={className} size={size} />;
      } else icon = TiVideo;
      break;
    case '海島假期':
      if (withJSX) {
        icon = <GiIsland className={className} />;
        if (size !== '') icon = <GiIsland className={className} size={size} />;
      } else icon = GiIsland;
      break;
    case '都會自由行':
      if (withJSX) {
        icon = <GiModernCity className={className} />;
        if (size !== '') icon = <GiModernCity className={className} size={size} />;
      } else icon = GiModernCity;
      break;
    case '近期分享':
      if (withJSX) {
        icon = <FaRegPaperPlane className={className} />;
        if (size !== '') icon = <FaRegPaperPlane className={className} size={size} />;
      } else icon = FaRegPaperPlane;
      break;
    default:
      if (withJSX) {
        icon = <FaTag className={className} />;
        if (size !== '') icon = <FaTag className={className} size={size} />;
      } else icon = FaTag;
      break;
  }
  return icon;
};
