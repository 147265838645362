import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
// import { getScreenWidth } from '../../utils/helpers';
// import { FontLoadedContext } from '../../layouts/Layout';
import config from '../../../content/meta/meta-config';
import Menu from '../Menu';
// import debounce from 'just-debounce-it';
// import avatar from '../../images/avatar/avatar.jpg';
import AvatarPlane from '../../images/avatar/tfl-plane.svg';
// import { scrollToContent } from '../../templates/IndexTemplate';
import { scrollToContent } from '../Common/scroll-to';

import { styled } from '@linaria/react';

function isNumeric(value) {
  return /^\d+$/.test(value);
}
const Header = (props) => {
  const data = useStaticQuery(graphql`query headerQuery {
  pages: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "//pages//"}, fields: {prefix: {regex: "/^dynamic-menu-page/"}}}
    sort: {frontmatter: {menuIndex: ASC}}
  ) {
    edges {
      node {
        fields {
          slug
          prefix
        }
        frontmatter {
          title
          menuTitle
        }
      }
    }
  }
}`);
  const [fixed, setFixed] = useState(false);
  // const [screenWidth, setScreenWidth] = useState(getScreenWidth());

  const visibilitySensorChange = (isVisible) => {
    if (isVisible) {
      setFixed(false);
    } else {
      setFixed(true);
    }
  };

  const getHeaderStyle = (isFixed, path) => {
    const classes = ['header'];
    // console.log(
    //   'isNumeric',
    //   isNumeric(
    //     path
    //       .replace(/\/$/, '')
    //       .split('/')
    //       .pop()
    //   )
    // );
    // const fixedClass = isFixed ? 'fixed ' : '';
    if (isFixed) classes.push('fixed');
    // const homepage = 'homepage';
    // const homepage =
    //   !path ||
    //   path.trim() === '' ||
    //   path === '/' ||
    //   path === '/travel' ||
    //   path === '/travel/' ||
    //   path === '/travel-tahiti' ||
    //   path === '/travel-tahiti/' ||
    //   path === '/travel-phu-quoc' ||
    //   path === '/travel-phu-quoc/' ||
    //   path === '/travel-bali' ||
    //   path === '/travel-bali/' ||
    //   path === '/travel-dubai' ||
    //   path === '/travel-dubai/' ||
    //   path === '/food' ||
    //   path === '/food/'
    //     ? 'homepage'
    //     : '';

    // using homepage style for header TODO: change style name
    if (
      path &&
      path.trim() !== '' &&
      !path.includes('/new') &&
      !path.includes('/tags') &&
      !path.includes('/category') &&
      !path.includes('/search') &&
      !path.includes('/about') &&
      !path.includes('/terms-and-conditions') &&
      !path.includes('/cookies-policy') &&
      !path.includes('/privacy-policy') &&
      !path.includes('/ad-policy') &&
      !path.includes('/contact') &&
      !path.includes('/404') &&
      !isNumeric(
        path
          .replace(/\/$/, '') //remove last trailing slash
          .split('/')
          .pop()
      )
    )
      classes.push('homepage');
    return classes.join(' ');
  };
  const {
    pages: { edges: pages },
  } = data;
  const { path, theme } = props;
  const headerClasses = getHeaderStyle(fixed, path);

  // useEffect(() => {
  //   console.debug('header useEffect: mount path:', path);
  // let mounted = true;
  // handleWindowSizeChange();
  // window.addEventListener(
  //   'resize',
  //   debounce(() => {
  //     if (mounted) handleWindowSizeChange();
  //   }, 400)
  // );
  // return () => {
  //   console.debug('header useEffect: unmount');
  // mounted = false;
  // window.removeEventListener('resize', handleWindowSizeChange());
  //   };
  // }, []);

  // const handleWindowSizeChange = () => {
  //   console.debug('header handleWindowSizeChange');
  //   setScreenWidth(getScreenWidth());
  // };
  // console.debug('screenWidth to render', screenWidth);

  // console.debug(`header - path:"${path}"`);
  return (
    <React.Fragment>
      <HeaderStyled {...props} className={headerClasses}>
        {path === '/' ? (
          <a onClick={scrollToContent} className="logoType scrollToTop">
            <LogoStyled theme={theme}>
              {/* <img src={config.gravatarImgMd5 === '' ? avatar : config.gravatarImgMd5} alt={config.siteTitle} /> */}
              <AvatarPlane />
            </LogoStyled>
            <div className="type">
              {fixed ? (
                <H1Styled theme={theme}>{config.headerSubTitle}</H1Styled>
              ) : (
                <>
                  <H1Styled theme={theme}>{config.headerSubTitle}</H1Styled>
                  <H2Styled theme={theme}>{config.headerTitle}</H2Styled>
                </>
              )}
            </div>
            {/* <div className="type">
                  <h2>{config.headerSubTitle}</h2>
                </div> */}
          </a>
        ) : (
          <Link to="/" className="logoType">
            <LogoStyled theme={theme}>
              {/* <img src={config.gravatarImgMd5 === '' ? avatar : config.gravatarImgMd5} alt={config.siteTitle} /> */}
              <AvatarPlane />
            </LogoStyled>
            <div className="type">
              {fixed ? (
                <H1Styled theme={theme}>{config.headerSubTitle}</H1Styled>
              ) : (
                <>
                  <H1Styled theme={theme}>{config.headerSubTitle}</H1Styled>
                  <H2Styled theme={theme}>{config.headerTitle}</H2Styled>
                </>
              )}
            </div>
            {/* <div className="type">
                  <h2>{config.headerSubTitle}</h2>
                </div> */}
          </Link>
        )}
        {/* <Link to="/" className="logoType">
          <div className="logo">
            <AvatarPlane />
          </div>
          <div className="type">
            {fixed ? (
              <h1>{config.headerSubTitle}</h1>
            ) : (
              <>
                <h1>{config.headerSubTitle}</h1>
                <h2>{config.headerTitle}</h2>
              </>
            )}
          </div>
        </Link> */}

        {/* <FontLoadedContext.Consumer> */}
        {/* {loaded => <Menu path={path} fixed={fixed} pages={pages} theme={theme} />} */}
        {/* </FontLoadedContext.Consumer> */}
        <Menu path={path} fixed={fixed} pages={pages} theme={theme} />
      </HeaderStyled>
      <VisibilitySensor onChange={visibilitySensorChange}>
        <SensorStyled {...props} />
      </VisibilitySensor>
    </React.Fragment>
  );
};

Header.propTypes = {
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Header;

const HeaderStyled = styled.header`
  align-items: center;
  justify-content: center;
  background-color: var(--bg);
  display: flex;
  height: ${(props) => props.theme.header.height.default};
  position: relative;
  top: 0;
  width: 100%;
  align-items: center;
  transition: color ${(props) => props.theme.time.duration.default} ease-out,
    background ${(props) => props.theme.time.duration.default} ease-out;

  a.logoType {
    align-items: center;
    display: flex;
    flex-direction: row;
    color: ${(props) => props.theme.color.neutral.gray.j};
    z-index: 1;

    .logo {
      flex-shrink: 0;
    }
  }
  a.scrollToTop {
    cursor: alias;
  }

  &.homepage {
    position: absolute;
    background-color: transparent;
    height: ${(props) => props.theme.header.height.homepage};
  }

  &.homepage:not(.fixed) {
    a.logoType,
    h1 {
      color: ${(props) =>
        props.path === '/' ? props.theme.color.neutral.gray.j : props.theme.color.neutral.white};
    }
    h2 {
      color: ${(props) =>
        props.path === '/'
          ? props.theme.color.neutral.gray.b
          : props.path.includes('/travel') ||
            props.path.includes('/travel-tahiti') ||
            props.path.includes('/travel-phu-quoc') ||
            props.path.includes('/london-eye')
          ? props.theme.color.neutral.gray.i
          : props.theme.color.neutral.gray.e};
    }
  }

  @media (min-width: 768px) {
    padding: ${(props) => props.theme.space.inset.l};

    &.homepage {
      height: ${(props) => props.theme.header.height.homepage};
    }
  }

  @media (max-width: 1024px) {
    &.homepage {
      .logo {
        border: none;
      }

      a.logoType,
      h1 {
        color: ${(props) => props.theme.color.neutral.white};
      }
      h2 {
        color: ${(props) => props.theme.color.neutral.gray.d};
      }
    }
  }

  @media (min-width: 1024px) {
    align-items: center;
    background-color: var(--bg);
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    justify-content: space-between;
    transition: padding 0.5s, color ${(props) => props.theme.time.duration.default} ease-out,
      background ${(props) => props.theme.time.duration.default} ease-out;

    &.fixed {
      height: ${(props) => props.theme.header.height.fixed};
      background-color: var(--bg);
      left: 0;
      padding: 0 ${(props) => props.theme.space.m};
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 2;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

      h1 {
        margin: ${(props) => props.theme.space.stack.xxs};
      }

      h2 {
        display: none;
      }
    }
    a.logoType {
      text-align: left;
      flex-direction: row;
      flex-shrink: 0;
      width: auto;
      z-index: 1;
    }
  }

  @media (min-width: 2560px) {
    &.homepage {
      font-size: ${(props) => props.theme.header.menu.itemListSize};
    }
  }
`;

const H1Styled = styled.h1`
  font-size: ${(props) => props.theme.font.size.m};
  font-weight: ${(props) => props.theme.font.weight.standard};
  margin: ${(props) => props.theme.space.stack.xs};
`;

const H2Styled = styled.h2`
  font-weight: ${(props) => props.theme.font.weight.standard};
  font-size: ${(props) => props.theme.font.size.xxs};
  letter-spacing: 0;
  margin: 0;

  @media (min-width: 1024px) {
    animation-duration: ${(props) => props.theme.time.duration.default};
    animation-name: h2Entry;
    @keyframes h2Entry {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

const LogoStyled = styled.div`
  border-radius: 65% 75%;
  border: 1px solid var(--border-line-color);
  display: inline-block;
  height: 44px;
  margin: ${(props) => props.theme.space.inline.default};
  overflow: hidden;
  width: 44px;
  transition: all 0.5s;

  .homepage & {
    height: 60px;
    width: 60px;
  }

  img {
    width: 100%;
  }

  @media (min-width: 1024px) {
    margin: ${(props) => props.theme.space.inline.default};

    .fixed & {
      height: 36px;
      width: 36px;
    }

    .header.homepage:not(.fixed) & {
      border: none;
    }
  }
`;

const SensorStyled = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  right: 0;
  height: 1px;
  top: ${(props) =>
    props.path === '/' ||
    props.path.includes('/travel') ||
    props.path.includes('/travel-tahiti') ||
    props.path.includes('/travel-phu-quoc') ||
    props.path.includes('/travel-bali') ||
    props.path.includes('/travel-dubai') ||
    props.path.includes('/food')
      ? props.theme.header.height.homepage
      : props.theme.header.height.default};
`;
