import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FiInstagram } from '@react-icons/all-files/fi/FiInstagram';
import { FiFacebook } from '@react-icons/all-files/fi/FiFacebook';
import { FiYoutube } from '@react-icons/all-files/fi/FiYoutube';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiRss } from '@react-icons/all-files/fi/FiRss';
import { FaFlipboard } from '@react-icons/all-files/fa/FaFlipboard';
import { FaRegEdit } from '@react-icons/all-files/fa/FaRegEdit';
import { Link } from 'gatsby';
import { getScreenWidth } from '../../utils/helpers';
import { styled } from '@linaria/react';
import debounce from 'just-debounce-it';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const Footer = (props) => {
  const [bgImageUrl, setBgImageUrl] = useState('');
  const { html, theme } = props;
  let bgStyle4iPhone;
  if (typeof window !== 'undefined') {
    bgStyle4iPhone =
      window.matchMedia('(max-width: 768px)') ||
      window.matchMedia('(max-device-width: 768px)') ||
      window.matchMedia('(max-device-width: 1024px)');
  }

  let footerFigureBgStyle;
  if (bgStyle4iPhone && bgStyle4iPhone.matches) {
    footerFigureBgStyle = {
      background: `linear-gradient(var(--bg), rgba(0, 0, 0, 0) 30%), url(${bgImageUrl}) scroll center/cover no-repeat`,
    };
  } else {
    footerFigureBgStyle = {
      background: `linear-gradient(var(--bg), rgba(0, 0, 0, 0) 30%), url(${bgImageUrl}) fixed center/cover no-repeat`,
    };
  }

  const today = new Date();
  const currentYear = today.getFullYear();

  useEffect(() => {
    // console.log('footer useEffect: mount');
    let mounted = true;
    const { backgrounds } = props;
    handleWindowSizeChange(backgrounds);
    window.addEventListener(
      'resize',
      debounce(() => {
        if (mounted) handleWindowSizeChange(backgrounds);
      }, 400)
    );
    return () => {
      // console.log('footer useEffect: unmount');
      mounted = false;
      window.removeEventListener('resize', handleWindowSizeChange(backgrounds));
    };
  }, []);

  const handleWindowSizeChange = (bg) => {
    setBgImageUrl(getBackgroundImageUrl(bg));
  };

  const getBackgroundImageUrl = (bg) => {
    const jsScreenWidth = getScreenWidth();
    let imageUrl = bg.footerBgMobile;

    if (typeof window !== 'undefined') {
      const mqRetinaDesktop = window.matchMedia(
        '(min-resolution: 192dpi) and (-webkit-min-device-pixel-ratio: 2)'
      );
      const mqFor4K = window.matchMedia('(min-width: 2560px)');
      const mqForQHD = window.matchMedia('(min-width: 1800px)');
      const mqForDesktop = window.matchMedia('(min-width: 1024px)');
      const mqForTablet = window.matchMedia('(min-width: 600px)');
      if (mqFor4K.matches || (mqRetinaDesktop.matches && mqForDesktop.matches)) {
        // console.log(`bg.footerBgFourK or for retina`);
        imageUrl = bg.footerBgFourK;
      } else if (mqForQHD.matches) {
        // console.log(`bg.footerBgQHD`);
        imageUrl = bg.footerBgQHD;
      } else if (mqForDesktop.matches) {
        // console.log(`bg.footerBgDesktop`);
        imageUrl = bg.footerBgDesktop;
      } else if (mqForTablet.matches || jsScreenWidth >= 760) {
        // console.log(`bg.footerBgTablet`);
        imageUrl = bg.footerBgTablet;
      }
      // else {
      // console.log(`bg.footerBgMobile`);
      // }
    }
    // console.log('TCL: footer imageUrl', imageUrl);

    return imageUrl;
  };

  // ***Photo by Fezbot2000 on Unsplash - bondi beach
  return (
    <React.Fragment>
      {/* <footer className="footer" dangerouslySetInnerHTML={{ __html: html }}> */}
      <FooterStyled theme={theme}>
        <figure style={footerFigureBgStyle}>
          <FooterFigureContent>
            <FooterSpace />
            <FooterTitle>
              <h1 className="main-title">The Fabulous Lifestyles</h1>
              <h2>不藏私旅行煮藝</h2>
            </FooterTitle>
            <FooterBar1 theme={theme} />
            <FooterBar2 theme={theme} />
            <FooterFlexContainer className="footer-links1">
              <Link to="/terms-and-conditions/">服務條款</Link>
              <div style={{ color: '#514f4d' }}>
                <Link to="/privacy-policy/">隱私政策</Link> & <Link to="/cookies-policy/">Cookies條款</Link>
              </div>
              <Link to="/ad-policy/">廣告條款</Link>
            </FooterFlexContainer>
            <FooterSocialMedia>
              <div className="footer-social-media-list">
                <Tooltip title="Youtube 粉絲頻道" TransitionComponent={Zoom} arrow>
                  <a
                    href="https://www.youtube.com/channel/UC9WGx5GDoqcEqYm31ZonKfw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiYoutube />
                  </a>
                </Tooltip>
                <Tooltip title="臉書粉絲頁" TransitionComponent={Zoom} arrow>
                  <a href="https://www.facebook.com/FabulousRecipe" target="_blank" rel="noopener noreferrer">
                    <FiFacebook />
                  </a>
                </Tooltip>
                <Tooltip title="Instagram 粉絲頁" TransitionComponent={Zoom} arrow>
                  <a
                    href="https://www.instagram.com/p/B32qjhdjGu1/?igshid=10h9h3updjocz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiInstagram />
                  </a>
                </Tooltip>
                <Tooltip title="Flipboard 雜誌" TransitionComponent={Zoom} arrow>
                  <a
                    href="https://flipboard.com/@joyfulelement/the-fabulous-lifestyles-5q3n2ti3y"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFlipboard />
                  </a>
                </Tooltip>
                <Tooltip title="填寫回饋函" TransitionComponent={Zoom} arrow>
                  <a href="https://forms.gle/RAFa3GNSM2q97bSY9" target="_blank" rel="noopener noreferrer">
                    <FaRegEdit />
                  </a>
                </Tooltip>
                <Tooltip title="聯絡我們" TransitionComponent={Zoom} arrow>
                  <Link to="/contact/">
                    <FiMail />
                  </Link>
                </Tooltip>
                <Tooltip title="RSS 訂閱" TransitionComponent={Zoom} arrow>
                  <a
                    href="https://thefabulouslifestyles.com/rss.xml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiRss />
                  </a>
                </Tooltip>
              </div>
              <FooterBar theme={theme} />
            </FooterSocialMedia>
            <FooterFlexContainer className="footer-links2">
              <Link to="/about/">關於我們</Link>
              <Link to="/search/">搜尋文章</Link>
              <Link to="/contact/">聯絡我們</Link>
            </FooterFlexContainer>
          </FooterFigureContent>
        </figure>
        <FooterCopyrightsBottom theme={theme}>
          <span>
            <p>Copyright © 2019-{currentYear}</p>
          </span>
          <span dangerouslySetInnerHTML={{ __html: html }} />
          <span>
            <p>
              網頁設計＋建置: <Link to="/about/">櫻桃與魚</Link>
            </p>
          </span>
        </FooterCopyrightsBottom>
      </FooterStyled>
    </React.Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired,
  backgrounds: PropTypes.object,
};

export default Footer;

const FooterStyled = styled.footer`
  background: ${(props) => props.theme.color.neutral.lightgray};

  h1,
  h2 {
    color: ${(props) => props.theme.color.neutral.gray.k};
  }

  p a {
    color: var(--textLink2);
  }
  p a:hover {
    color: var(--textLink2Hover);
  }
  a {
    color: ${(props) => props.theme.color.neutral.gray.i};
  }
  a:hover {
    color: midnightblue;
  }

  @media (min-width: 2560px) {
    font-size: ${(props) => props.theme.footer.fontSize};

    h1 {
      font-size: calc(${(props) => props.theme.hero.h1.size} * 1.7);
    }
    h2 {
      font-size: calc(${(props) => props.theme.hero.h2.size} * 1.7);
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 42px;
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: ${(props) => props.theme.hero.h1.size};
    }
    h2 {
      font-size: ${(props) => props.theme.hero.h2.size};
    }
  }
  @media (min-width: 1800px) {
    h1 {
      font-size: calc(${(props) => props.theme.hero.h1.size} * 1.3);
    }
    h2 {
      font-size: calc(${(props) => props.theme.hero.h2.size} * 1.3);
    }
  }
  @media (max-width: 680px) {
    h1 {
      font-size: calc(${(props) => props.theme.hero.h1.size} * 0.7);
    }
    h2 {
      font-size: calc(${(props) => props.theme.hero.h2.size} * 0.7);
    }
  }
  @media (min-width: 680px) {
    h1 {
      font-size: calc(${(props) => props.theme.hero.h1.size} * 0.9);
    }
    h2 {
      font-size: calc(${(props) => props.theme.hero.h2.size} * 0.9);
    }
  }
`;

const FooterFigureContent = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  grid-template-areas:
    'footer-space footer-space footer-space'
    'footer-bar1 footer-title footer-bar2'
    'footer-links1 footer-social-media footer-links2';
  grid-gap: 1em;
  margin: 0 auto;
  padding: 60px 50px;
  overflow: hidden;

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(30px, 1fr);
    grid-template-areas:
      'footer-space'
      'footer-title'
      'footer-social-media'
      'footer-links1'
      'footer-bar1'
      'footer-links2';
    grid-gap: unset;
    padding: 60px 50px;
  }

  @media (max-width: 980px) {
    padding: 60px 0px;
  }
`;

const FooterSpace = styled.div`
  grid-area: footer-space;
`;

const FooterTitle = styled.div`
  grid-area: footer-title;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;

  .main-title {
    font-family: 'Niconne', cursive, 'Arial', sans-serif;
    font-weight: normal;
    padding-bottom: 0.2em;
  }

  @media (max-width: 680px) {
    margin-bottom: 50px;
  }
`;

const FooterBar1 = styled.div`
  grid-area: footer-bar1;
  margin-top: 32px;
  border-top: 2px solid ${(props) => props.theme.color.neutral.gray.h};
`;

const FooterBar2 = styled.div`
  grid-area: footer-bar2;
  margin-top: 32px;
  border-top: 2px solid ${(props) => props.theme.color.neutral.gray.h};
  @media (max-width: 680px) {
    display: none;
  }
`;

const FooterFlexContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &.footer-links1 {
    grid-area: footer-links1;
  }
  &.footer-links2 {
    grid-area: footer-links2;
  }
`;

const FooterSocialMedia = styled.div`
  grid-area: footer-social-media;
  display: flex;
  flex-direction: column;

  .footer-social-media-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 2em;
    flex-basis: 1em;
  }

  .footer-social-media-list a {
    cursor: alias;
  }
`;

const FooterBar = styled.div`
  border-left: 2px solid ${(props) => props.theme.color.neutral.gray.h};
  height: 10px;
  align-self: center;
  flex-grow: 1;
  margin-top: 1em;

  @media (max-width: 680px) {
    display: none;
  }
`;

const FooterCopyrightsBottom = styled.section`
  background: ${(props) => props.theme.footer.copyrightBackgroundColor};
  padding: ${(props) => props.theme.space.inset.default};
  color: ${(props) => props.theme.color.neutral.white};
  font-size: ${(props) => props.theme.font.size.xxs};
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  span {
    p {
      color: ${(props) => props.theme.color.neutral.white};
      padding: ${(props) => props.theme.space.xxs} ${(props) => props.theme.space.xs};
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 17px 20px 40px;
  }
`;
