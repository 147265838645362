import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import config from '../../../content/meta/meta-config';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaTags } from '@react-icons/all-files/fa/FaTags';
import { FaSitemap } from '@react-icons/all-files/fa/FaSitemap';
import { getCategoryIcon } from '../Common/icon-type';
import { FiShare } from '@react-icons/all-files/fi/FiShare';
import { getScreenWidth, joinPath } from '../../utils/helpers';
import { AiFillLike } from '@react-icons/all-files/ai/AiFillLike';
import { IconContext } from '@react-icons/all-files';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import Item from './Item';
import Expand from './Expand';
import DarkModeToggle from './dark-mode-toogle';
import debounce from 'just-debounce-it';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';

const toggleComponentIconStyle = { opacity: 0.7, margin: '0 5px 0 5px' };
const toggleComponent = (
  <IconContext.Provider value={{ style: toggleComponentIconStyle }}>
    <DarkModeToggle />
  </IconContext.Provider>
);

const useStyles = makeStyles({
  drawerContainer: {
    backgroundColor: '#edfafd',
  },
});

// will contain references to rendered DOM elements of menu
// needs to be outside menu component so won't get reset upon re-render i.e the menu won't be able to expand
let renderedItems = [];

const Menu = (props) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { theme, path } = props;
  const classes = useStyles();
  const toggleDrawer = (targetState) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(targetState);
  };
  const toggleDrawerComponent = (label) => (
    <a onClick={toggleDrawer(true)}>
      <AiFillLike />
      {label}
    </a>
  );

  const itemList = React.createRef();

  useEffect(() => {
    // console.debug('menu useEffect: mount (path, isFixed, screenWidth) ', props.path, props.fixed, screenWidth);
    let mounted = true;
    const pages = props.pages.map((page) => {
      let icon = getCategoryIcon(page.node.frontmatter.menuTitle);
      return {
        to: page.node.fields.slug,
        label: page.node.frontmatter.menuTitle
          ? page.node.frontmatter.menuTitle
          : page.node.frontmatter.title,
        icon: icon,
      };
    });

    // console.log(`pages: ${JSON.stringify(pages)}`);

    const menuItems = [
      { label: 'toggleDarkMode', component: toggleComponent }, // label is used as an item key property
      { label: 'likeDrawer', component: toggleDrawerComponent('讚賞') },
      // typeof navigator !== 'undefined' && navigator && navigator.share
      //   ? {
      //       shareData: { url: joinPath(config.siteUrl, path) },
      //       label: '分享',
      //       icon: FiShare
      //     }
      //   : undefined,
      { to: '/', label: '首頁', icon: FaHome },
      { to: '/new/', label: '最新文章', icon: getCategoryIcon('近期分享') },
      { to: '/travel/', label: '旅行日誌', icon: getCategoryIcon('旅行日誌') },
      { to: '/food/', label: '美味廚房', icon: getCategoryIcon('美味廚房') },
      { to: '/tags/', label: '標籤', icon: FaTags },
      { to: '/category/', label: '類別', icon: FaSitemap },
      { to: '/search/', label: '搜尋', icon: FaSearch },
      ...pages, // additional static page content under /content/pages
      // { to: '/contact/', label: 'Contact', icon: FaEnvelope }
    ];
    // console.log('menuItems:', menuItems);

    // add share button for supported device
    if (typeof navigator !== 'undefined' && navigator && navigator.share) {
      // console.log('navigator => Add share menu item');
      menuItems.splice(2, 0, {
        shareData: { url: joinPath(config.siteUrl, path) },
        label: '分享',
        icon: FiShare,
      });
    }
    //   console.debug('menu useEffect: close menu');
    setItems(menuItems);
    closeMenu();
    renderedItems = getRenderedItems();
    hideOverflowedMenuItems(screenWidth);

    window.addEventListener(
      'resize',
      debounce(() => {
        if (mounted) handleWindowSizeChange();
      }, 400)
    );
    return () => {
      // console.debug('menu useEffect: unmount');
      mounted = false;
      window.removeEventListener('resize', handleWindowSizeChange());
    };
  }, [props.path, props.fixed, screenWidth]);

  const handleWindowSizeChange = () => {
    // console.debug('menu handleWindowSizeChange');
    // const sw = getScreenWidth();
    // console.debug('Menu -> handleWindowSizeChange -> sw', sw);
    // console.debug('Menu -> handleWindowSizeChange -> state before setScreenWidth', screenWidth);
    // console.debug('Menu -> handleWindowSizeChange -> change screenWidth to:', sw);
    setScreenWidth(getScreenWidth());
    // if (sw >= 710) {
    //   console.debug('Menu -> handleWindowSizeChange -> closeMenu >= 710', sw);
    // closeMenu();
    // }
  };

  const getRenderedItems = () => {
    // const itemList = this.itemList.current;
    return Array.from(itemList.current.children);
  };

  const hideOverflowedMenuItems = (sw) => {
    // console.debug('Menu -> hideOverflowedMenuItems');
    // last two value controls extra paddings needed for the menu above/below desktop width
    const PADDING_AND_SPACE_FOR_MORELINK = sw >= 1024 ? 400 : 50;

    // will hide items that is overflowing beyond the maxWidth
    const maxWidth = sw - PADDING_AND_SPACE_FOR_MORELINK;
    // console.debug('Menu -> hideOverflowedMenuItems -> maxWidth', maxWidth);

    setHiddenItems([]); // clears previous state

    const newMenu = renderedItems.reduce(
      (result, item) => {
        item.classList.add('item');
        item.classList.remove('hideItem');

        const currentCumulativeWidth = result.cumulativeWidth + item.offsetWidth;
        // console.debug('newMenu -> hideOverflowedMenuItems -> currentCumulativeWidth', currentCumulativeWidth);
        result.cumulativeWidth = currentCumulativeWidth;

        if (!item.classList.contains('more') && currentCumulativeWidth > maxWidth) {
          const link = item.querySelector('a');

          if (link) {
            item.classList.add('hideItem');
            item.classList.remove('item');
            result.hiddenItems.push({
              to: link.getAttribute('data-slug'),
              label: link.text,
            });
          }
        }
        return result;
      },
      { visibleItems: [], cumulativeWidth: 0, hiddenItems: [] }
    );

    // this.setState(prevState => ({ hiddenItems: newMenu.hiddenItems }));
    setHiddenItems(newMenu.hiddenItems);
    // console.debug('Menu ->  newMenu.hiddenItems', newMenu.hiddenItems);
  };

  const toggleMenu = (e) => {
    e.preventDefault();

    // console.debug('Menu -> toggleMenu -> renderedItems', renderedItems);
    // console.debug('Menu -> toggleMenu -> screenWidth', screenWidth);
    if (screenWidth < 1024) {
      renderedItems.map((item) => {
        const oldClass = open ? 'showItem' : 'hideItem';
        const newClass = open ? 'hideItem' : 'showItem';

        if (item.classList.contains(oldClass)) {
          // console.debug('Menu -> toggleMenu -> newClass to add', newClass);
          // console.debug('Menu -> toggleMenu -> oldClass to remove', oldClass);
          item.classList.add(newClass);
          item.classList.remove(oldClass);
        }
      });
    }

    // this.setState(prevState => ({ open: !prevState.open }));
    setOpen(!open);
  };

  const closeMenu = () => {
    if (open) {
      // this.setState({ open: false });
      setOpen(false);
      if (screenWidth < 1024) {
        renderedItems.map((item) => {
          if (item.classList.contains('showItem')) {
            item.classList.add('hideItem');
            item.classList.remove('item');
          }
        });
      } else {
        renderedItems.map((item) => {
          if (item.classList.contains('showItem')) {
            item.classList.remove('showItem');
            item.classList.add('item');
          }
        });
      }
    }
  };

  // const navClass = [];
  // if (open) navClass.push('open');

  // console.debug('renderedItems', renderedItems); // when empty, the menu won't be able to expand

  return (
    <React.Fragment>
      <NavStyled theme={theme} open={open} className={open ? 'open' : ''} rel="js-menu">
        <UnorderedList theme={theme} className="itemList" ref={itemList}>
          {items.map((item) =>
            item ? <Item item={item} key={item.label} icon={item.icon} theme={theme} path={path} /> : null
          )}
        </UnorderedList>
        {hiddenItems.length > 0 && <Expand onClick={toggleMenu} theme={theme} path={path} />}
        {open && screenWidth >= 1024 && (
          <UnorderedHiddenList theme={theme}>
            {hiddenItems.map((item) =>
              item ? <Item item={item} key={item.label} hiddenItem theme={theme} /> : null
            )}
          </UnorderedHiddenList>
        )}
        <Drawer
          anchor="bottom"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          classes={{ paper: classes.drawerContainer }}
        >
          <p className={drawerContainerText}>
            如果您喜歡我們的文章，歡迎在下面 👇🏻 的『拍手讚賞鍵』👏🏻 幫忙按五下 👏🏻！
            就可以化讚為賞，免費贊助，以支持我們繼續創作 🙏🏼 謝謝您 ！
          </p>
          <LikecoinButton className="likecoin-embed">
            <iframe
              loading="lazy"
              scrolling="no"
              frameBorder="0"
              sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              style={{ height: '212px', width: '100%', overflow: 'hidden' }}
              src={`https://button.like.co/in/embed/fabulous-lifestyles/button?referrer=${joinPath(
                config.siteUrl,
                path
              )}/`}
            ></iframe>
          </LikecoinButton>
          <div className={drawerDismissBtn}>
            <Button
              id="close-btn"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              variant="outlined"
              color="primary"
              disableElevation
            >
              瞭解了
            </Button>
          </div>
        </Drawer>
      </NavStyled>
    </React.Fragment>
  );
};

Menu.propTypes = {
  path: PropTypes.string.isRequired,
  fixed: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Menu;

const NavStyled = styled.nav`
  align-items: center;
  background: var(--menuBg);
  bottom: 0;
  display: flex;
  flex-grow: 1;
  left: 0;
  max-height: ${(props) => (props.open ? '1000px' : '50px')};
  position: fixed;
  width: 100%;
  z-index: 5;
  transition: all ${(props) => props.theme.time.duration.default} ease-out;

  @media (max-width: 1023px) {
    background-color: var(--menuBg);

    &::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background: ${(props) => props.theme.color.brand.primary};
    }

    &.open {
      padding: ${(props) => props.theme.space.inset.m};
    }

    .header:not(.fixed) & {
      bottom: -100px;
    }
  }
  @media screen and (min-width: 1024px) {
    border-top: none;
    background: transparent;
    display: flex;
    position: relative;
    justify-content: flex-end;
    padding-left: 50px;
    transition: color ${(props) => props.theme.time.duration.default} ease-out,
      background ${(props) => props.theme.time.duration.default} ease-out;
  }
`;

const UnorderedList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    transition: color ${(props) => props.theme.time.duration.default} ease-out,
      background ${(props) => props.theme.time.duration.default} ease-out;
  }
  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
    padding: 0;
  }
`;

const UnorderedHiddenList = styled.ul`
  @media screen and (min-width: 1024px) {
    list-style: none;
    margin: 0;
    position: absolute;
    background: var(--menuDesktopExpandBg);
    border: 1px solid ${(props) => props.theme.line.color};
    top: 48px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${(props) => props.theme.space.m};
    border-radius: ${(props) => props.theme.size.radius.small};
    border-top-right-radius: 0;

    &:after {
      content: '';
      background: var(--menuDesktopExpandBg);
      z-index: 10;
      top: -10px;
      right: -1px;
      width: 44px;
      height: 10px;
      position: absolute;
      border-left: 1px solid ${(props) => props.theme.line.color};
      border-right: 1px solid ${(props) => props.theme.line.color};
    }

    .homepage:not(.fixed) & {
      border: 1px solid transparent;
      background: var(--menuDesktopExpandBg);
      top: 50px;

      &:after {
        top: -11px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        background: var(--menuDesktopExpandBg);
      }
    }

    .fixed & {
      top: 44px;
    }
  }
`;

const LikecoinButton = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 485px;
  max-height: 240px;

  & > div {
    padding-top: 49.48454%;
  }
  & > iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const drawerContainerText = css`
  color: #222;
  font-size: 1.2em;
  line-height: 1.7;
  margin: 2em 2em 0em 2em;
  text-align: center;
`;

const drawerDismissBtn = css`
  text-align: center;
  margin-bottom: 2em;
`;
