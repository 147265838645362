module.exports = {
  siteTitle: 'The Fabulous Lifestyles 不藏私旅行煮藝', // <title>
  shortSiteTitle: '不藏私旅行煮藝', // <title> ending for posts and pages
  siteDescription:
    "「不藏私旅行煮藝」是以旅行和美食食譜為主的網站。內容包含旅行中大大小小的經驗、景點懶人包、飯店住宿、交通攻略...這些行前絕不能錯過的超佛心秘笈。美食食譜都是經過數次的研製，並綜合最成功的食材選擇和烹飪方式，實作實拍記錄下來。全部都在這網站上不藏私大公開。The Fabulous Lifestyles features content about travel and food. It offers practical travel advice that covers trip planning, logistics, and reviews on destination, resort & hotel...etc. Besides travelling, there are step-by-step homemade gourmet recipes that will appeal to everyone's taste buds.",
  siteUrl: process.env.TARGET_ADDRESS ? process.env.TARGET_ADDRESS : 'https://thefabulouslifestyles.com',
  pathPrefix: '',
  siteImage: '/preview.jpg',
  siteLanguage: 'zh-Hant-tw',

  /* author */
  authorName: '櫻桃與魚',
  authorTwitterAccount: '@thefabulouslifestyles',

  /* info */
  headerTitle: 'The Fabulous Lifestyles',
  headerSubTitle: '不藏私旅行煮藝',

  /* PWA manifest.json to be added as a homes screen shortcut on device */
  manifestName: '不藏私旅行煮藝',
  manifestShortName: '不藏私旅行煮藝', // max 12 characters
  manifestStartUrl: '/index.html',
  manifestBackgroundColor: 'white',
  manifestThemeColor: '#666',
  manifestDisplay: 'standalone',

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/avatar/avatar.jpg
  // Replace email adress with md5-code.
  // gravatarImgMd5: 'https://www.gravatar.com/avatar/e5615ea022a33fc177b270db50cd723b?s=60',
  // sometimes accessing avatar via gravatar resulted in network error, fallback to self hosting instead
  gravatarImgMd5: '',

  // social
  authorSocialLinks: [
    { name: 'github', url: 'https://github.com/joyfulelement' },
    // { name: 'twitter', url: 'https://twitter.com/' },
    { name: 'facebook', url: 'https://www.facebook.com/TheFabulousLifestyles' },
  ],
};
