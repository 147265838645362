export function getScreenWidth() {
  if (typeof window !== `undefined`) {
    // The window.innerWidth property allows menu to hide items when browser window adjusts
    // The window.screen.availWidth property returns the amount of horizontal space (in pixels) available to the window.
    return window.innerWidth;
    // return window.screen.availWidth;
  }
}

export function isWideScreen() {
  if (typeof window !== `undefined`) {
    const windowWidth = window.innerWidth;
    const mediaQueryL = 1024;

    return windowWidth >= mediaQueryL;
  }
}

export function timeoutThrottlerHandler(timeouts, name, delay, handler) {
  if (!timeouts[name]) {
    timeouts[name] = setTimeout(() => {
      timeouts[name] = null;
      handler();
    }, delay);
  }
}

export function insertScript(src, id, parentElement, type = null) {
  const script = window.document.createElement('script');
  // Note - Prefer 'defer' for 3rd party script over 'async' to guarantees scripts will be executed in the order they appear in the HTML and will not block the JS parser
  script.defer = true;
  script.id = id;
  if (type) {
    script.type = type;
    if (type === 'text/javascript') script.appendChild(document.createTextNode(src));
  } else script.src = src;

  parentElement.appendChild(script);

  return script;
}

export function removeScript(id, parentElement) {
  const script = window.document.getElementById(id);
  if (script) parentElement.removeChild(script);
}

export const joinPath = (...args) => {
  return args
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/[\/]*$/g, '');
      } else {
        return part.trim().replace(/(^[\/]*|[\/]*$)/g, '');
      }
    })
    .filter((x) => x.length)
    .join('/');
};

export const removeDuplicates = (data) => {
  return [...new Set(data)];
};

// combined posts includes markdown posts and contentful posts
export const sortCombinedAllPosts = (allPosts) =>
  // sort the post entries in descending order base on the published date
  allPosts.sort((postA, postB) => {
    if (postA.node?.fields?.prefix && postB.node?.fields?.prefix) {
      if (postA.node?.fields?.prefix > postB.node?.fields?.prefix) return -1;
      if (postA.node?.fields?.prefix < postB.node?.fields?.prefix) return 1;
      return 0;
    } else if (postA.node?.fields?.prefix && postB?.node?.datePublished) {
      if (postA.node?.fields?.prefix > postB?.node?.datePublished) return -1;
      if (postA.node?.fields?.prefix < postB?.node?.datePublished) return 1;
      return 0;
    } else if (postA?.node?.datePublished && postB.node?.fields?.prefix) {
      if (postA?.node?.datePublished > postB.node?.fields?.prefix) return -1;
      if (postA?.node?.datePublished < postB.node?.fields?.prefix) return 1;
      return 0;
    } else if (postA?.node?.datePublished && postB?.node?.datePublished) {
      if (postA?.node?.datePublished > postB?.node?.datePublished) return -1;
      if (postA?.node?.datePublished < postB?.node?.datePublished) return 1;
      return 0;
    }
  });

const getLanguage = () => {
  if (typeof window !== 'undefined') {
    return (
      window.navigator.userLanguage ||
      (window.navigator.languages && window.navigator.languages.length && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.systemLanguage ||
      'en'
    );
  }
};
// assume date string format is yyyy-mm-dd or ISO format (YYYY-MM-DDTHH:mm:ss.sssZ)
export const getLocaleDateString = (dateString) => {
  if (typeof window !== 'undefined') {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    let dateObj;
    if (dateString?.length === 10) {
      //yyyy-mm-dd
      const dateStrings = dateString.split('-');
      dateObj = new Date(dateStrings[0], dateStrings[1] - 1, dateStrings[2]); //month index is actual month - 1
    } else {
      // ISO format
      dateObj = new Date(dateString);
    }
    return dateObj.toLocaleDateString(getLanguage(), options);
  } else {
    return dateString;
  }
};
