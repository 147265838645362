import React, { forwardRef, useRef } from 'react';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import PropTypes from 'prop-types';

const separator = React.createRef(); //Note that useRef Hooks can only be called inside of the body of a function component.

export const scrollToContent = (e) => {
  separator.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
};

const ScrollTo = (props) => {
  const { isFirstPage } = props;
  return (
    <>
      {!props.isHr ? (
        <SeparatorStyled className={isFirstPage ? 'isFirstPage' : ''} ref={separator} />
      ) : (
        <hr className={hrStyled} ref={separator} />
      )}
    </>
  );
};

ScrollTo.propTypes = {
  isFirstPage: PropTypes.bool,
  isHr: PropTypes.bool,
};

export default ScrollTo;

const SeparatorStyled = styled.div`
  @media screen and (min-width: 980px) {
    &.isFirstPage {
      margin-top: 10px;
    }
  }
`;

const hrStyled = css`
  margin: 0;
  border: 0;
`;
