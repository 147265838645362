import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown';
import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@linaria/react';

const Expand = (props) => {
  const { onClick, theme, path } = props;

  return (
    <React.Fragment>
      <ButtonStyled theme={theme} to="#" onClick={onClick} aria-label="expand">
        <FaAngleDown size={30} />
      </ButtonStyled>
    </React.Fragment>
  );
};

Expand.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.object.isRequired,
  path: PropTypes.string,
};

export default Expand;

const ButtonStyled = styled.button`
  cursor: pointer;

  @media (max-width: 1023px) {
    background: var(--menuMobileExpandBg);
    border: 1px solid ${(props) => props.theme.color.brand.primary};
    border-radius: ${(props) => props.theme.size.radius.small} ${(props) => props.theme.size.radius.small} 0 0;
    border-bottom: none;
    position: absolute;
    left: 50%;
    top: -35px;
    width: 60px;
    height: 36px;
    overflow: hidden;
    z-index: 1;
    transform: translateX(-50%);

    &:focus {
      outline: none;

      svg {
        fill: ${(props) => props.theme.color.brand.primary};
      }
    }

    svg {
      transition: all 0.5s;
      transform: rotateZ(180deg);
      fill: ${(props) => props.theme.color.special.attention};
    }

    .open & svg {
      transform: rotateZ(0deg);
    }
  }

  @media (min-width: 1024px) {
    flex-shrink: 0;
    flex-grow: 0;
    width: 44px;
    height: 36px;
    background: transparent;
    margin-left: 10px;
    border-radius: ${(props) => props.theme.size.radius.small};
    border: 1px solid ${(props) => props.theme.line.color};
    display: flex;
    transition: background-color ${(props) => props.theme.time.duration.default};
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 1;

    &:focus,
    &:hover {
      outline: none;
    }

    svg {
      transition: all ${(props) => props.theme.time.duration.default};
      opacity: 0.5;
      color: var(--menuDesktopFixedExpandIcon);
    }

    .homepage:not(.fixed) & svg {
      opacity: 0.5;
      color: ${(props) =>
        props.path &&
        (props.path === '/' || props.path.includes('/travel-phu-quoc') || props.path.includes('/london-eye'))
          ? '#3e3e3c'
          : props.theme.color.neutral.white};
    }

    .homepage & {
      border: 1px solid transparent;
      background-color: hsl(0deg 0% 100% / 10%);

      &:hover {
        background-color: hsl(0deg 0% 100% / 40%);
      }
    }

    .open & {
      background-color: var(--menuDesktopExpandBg);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background-color: var(--menuDesktopExpandBg);
      }

      svg,
      .homepage:not(.fixed) & svg {
        opacity: 0.5;
        color: black;
        transform: rotate(180deg);
      }
    }

    .fixed & {
      border: 1px solid ${(props) => props.theme.line.color};
      height: 30px;
    }
  }
`;
