import React from 'react';
import Layout from './Layout';
import Layout404 from './Layout404';

export default props => {
  if (props.pageContext.layout === '404') {
    return <Layout404>{props.children}</Layout404>;
  }
  return <Layout {...props}>{props.children}</Layout>;
};
